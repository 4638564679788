import React, { useState } from 'react';
import { TextInput } from '../input/TextInput'

export function LoginView({ onClick, error }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    return (
            <React.Fragment>
              <TextInput
                label="Email"
                name="email"
                placeholder="example@email.com"
                onChange={event => {
                  setUsername(event.target.value);
                }}
              />
              <TextInput
                label="Password"
                name="password"
                type="password"
                placeholder="***********"
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
              <button
                onClick={() => {
                  onClick(username, password);
                }}
                >
                Login
              </button>
              <span>{error}</span>
          </React.Fragment>
    );
  }

