import React from 'react';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database' 
import * as _ from 'lodash'

firebase.initializeApp({
    apiKey: "AIzaSyCZD5k9mLCBD5xU5dfFqBgtor_BgtSuKNE",
    authDomain: "cblacrosse-43893.firebaseapp.com",
    databaseURL: "https://cblacrosse-43893.firebaseio.com",
    projectId: "cblacrosse-43893",
    storageBucket: "cblacrosse-43893.appspot.com",
    messagingSenderId: "604253532680",
    appId: "1:604253532680:web:6ba820ab78150431b6d7bd",
    measurementId: "G-Q4HTCX26X8"
});

const database = firebase.database()

export class Name {
  constructor(firstName, lastName) {
    this.firstName = firstName
    this.lastName = lastName
  }
}

export class AuthenticatedUser {
  constructor(id, name, database) {
    this.loggedIn = true
    this.id = id
    this.name = name
    this.database = database
  }
}

export function onAuthStateChange(callback) {
    return firebase.auth().onAuthStateChanged(user => {
      console.log("user: ", user)
      if (user) {
        const userRef = `users/${user.uid}/public`
        database.ref(userRef).on("value",  
          (snapshot) => {
              const val = snapshot.val()
              const name = _.get(val, 'name', {})
              callback(new AuthenticatedUser(user.uid, name, database))
          },
          (error) => {
            callback({ loggedIn: false })    
          }
        )
      } else {
        callback({ loggedIn: false })
      }
    });
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(username, password)
    .then(() => resolve())
    .catch(error => reject(error))
  })
}

export function logout() {
    firebase.auth().signOut();
}

const defaultUser = { loggedIn: false, email: "" };
export const UserContext = React.createContext(defaultUser);
export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
