import React from 'react';
import MessageLabel from '../text/MessageLabel'

function LandingPage() {
    return (
      <div>
        <MessageLabel>
          Hello from <br/>GB Lacrosse!
        </MessageLabel>
        <button type="button" className="btn btn-light btn-block"
          onClick={() => window.open("https://apps.apple.com/us/app/gb-lacrosse/id1664504538")}>
                  Download the app
          </button>
      </div>
    )
}

export default LandingPage