import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'

import styled from 'styled-components'
import * as _ from 'lodash'

import MessageLabel from '../text/MessageLabel'

const Container = styled.div`
    display: flex;
    flex-direction: column;
	align-items: center;
	justify-content: center;
`;

function redeemInvitation(token, userID, callback) {
    const requestData = {
        invitationKey: token,
        userKey: userID
    }
    const bodyString = JSON.stringify(requestData)
    return window.fetch("/api/redeemInvitation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: bodyString
      })
      .then(res => {
        // eslint-disable-next-line
        if (res.status == 200) {
            callback(null)
        } else {
            callback("Failed to redeem the invitation, please contact our support team at support@goldbarsports.tech.")
        }
      })
      .catch(() => {
        callback("Failed to redeem the invitation, please try again.")
      })
}

export default function UserJoinOrganization({ token, user }) {
    const [hasRequestedRedeem, setHasRequestedRedeem] = useState(false)
    const [hasFinishedRequest, setHasFinishedRequest] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    if (!hasRequestedRedeem) {
        redeemInvitation(token, user.id, (responseErrorMessage) => {
            setHasFinishedRequest(true)
            setErrorMessage(responseErrorMessage)
        })
        setHasRequestedRedeem(true)
    }
    if (!hasRequestedRedeem || !hasFinishedRequest) {
        return (
            <Container>
                <MessageLabel>Redeeming Invitation</MessageLabel>
                <Spinner animation="border"/>
            </Container>
        )
    } else if (_.isNil(errorMessage)) {
        return (
            <Container>
                <MessageLabel>Successfully joined the organization!</MessageLabel>
                <button type="button" className="btn btn-light btn-block"
                    onClick={() => window.open("https://www.cb-lacrosse.com")}>
                        Download the app
                </button>
            </Container>
        )
    } else {
       return <MessageLabel>{errorMessage}</MessageLabel>
    }
}